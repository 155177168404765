import React from 'react';
import './SideMenu.css';

function SideMenu({ isOpen }) {
  return (
    <div className={`side-menu ${isOpen ? 'open' : ''}`}>
      <img src="/icon4.png" alt="Icon 2" title="Turf Recycle Centers" className="header-icon1" />
      <ul className="side-menu-list">
        <li>About Us</li>
        <li>Contact Us</li>
        <li>Scheduale a Free Estimate</li>
        <li>Gallery</li> 
        
        <p className="lic-info">
  <a href="https://www.cslb.ca.gov/OnlineServices/CheckLicenseII/LicenseDetail.aspx?LicNum=940083" target="_blank" rel="noopener noreferrer">
    CSLB: C-27 Lic# 940083
  </a>
</p>
        
      </ul>
    </div>
  );
}

export default SideMenu;
